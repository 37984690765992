import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import BaseUrlContext from '../../contexts/baseUrl';

import './adminContacts.css';

function AdminContacts(props) {
  const baseApiUrl = useContext(BaseUrlContext);
  const [marketing, setMarketing] = useState('');
  const [booking, setBooking] = useState('');
  const [payment, setPayment] = useState('');
  const [geolocation, setGeolocation] = useState('');
  const [address, setAddress] = useState('');
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const fetchContacts = async (baseApiUrl) => {
    const res = await fetch(`${baseApiUrl}/api/contacts`);
    return await res.json();
  }

  useEffect(() => {
    fetchContacts(baseApiUrl).then(data => {
      setMarketing(data[0]);
      setBooking(data[1]);
      setPayment(data[2]);
      setGeolocation(data[3]);
      setAddress(data[4]);
    });
  }, []);
  
  const onSubmit = async (e) => {
    e.preventDefault();

    const body = JSON.stringify({ marketing, booking, payment, geolocation, address });

    const CONTACTS_ID = 1;
    const res = await fetch(`${baseApiUrl}/api/contacts/${CONTACTS_ID}`, {method: 'PUT', body, headers: {
      'Content-Type': 'application/json'
    }});

    if (res.status !== 200) {
      // Ошибка обновления контактов
    }

    setShow(true);
  };

  return (
    <div className="edit-room">
      <div>Изменить контакты</div>
      <Form className="edit-form" encType="multipart/form-data" onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Отдел маркетинга</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Отдел маркетинга"
            value={marketing}
            onChange={e => setMarketing(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Заказ и бронирование путевок</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Заказ и бронирование путевок"
            value={booking}
            onChange={e => setBooking(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Системы оплаты</Form.Label>
          <Form.Control
            as="textarea"
            rows={4} 
            placeholder="Системы оплаты"
            value={payment}
            onChange={e => setPayment(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Геолокация</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Геолокация"
            value={geolocation}
            onChange={e => setGeolocation(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Полный адрес</Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            placeholder="Полный адрес"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Сохранить изменения
        </Button>
      </Form>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Сохранение изменений</Modal.Title>
        </Modal.Header>
        <Modal.Body>Контакты успешно обновлены!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Ок
          </Button>
        </Modal.Footer>
      </Modal>
    </div>   
  );
}

export default AdminContacts;