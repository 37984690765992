const links = [
  {
    label: 'Бронирования',
    linkTo: 'booking',
    idx: 0
  },
  {
    label: 'Типы номеров',
    linkTo: 'rooms',
    idx: 1
  },
  {
    label: 'Услуги',
    linkTo: 'promotions',
    idx: 2
  },
  {
    label: 'Новости',
    linkTo: 'news',
    idx: 3
  },
  {
    label: 'Контакты',
    linkTo: 'contacts',
    idx: 4
  },
  {
    label: 'Статистика по дням',
    linkTo: 'statistics',
    idx: 5
  }
];

export default links;