import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { Tabs, Tab } from 'react-bootstrap';
import { columnDefs, columnDefsConfirmed } from './grid/columnDefs';
import AdminBookingForm from '../adminBookingForm';

import BaseApiUrlContext from '../../contexts/baseUrl';
import AdminBookingContext from '../../contexts/adminBookingContext';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './adminBooking.css';

const fetchBooking = async (baseApiUrl, tab) => {
  const res = await fetch(`${baseApiUrl}/api/booking/requests?isConfirmed=${tab === 'confirmed' ? 1 : 0}`);
  return await res.json();
}

function AdminBooking() {
  const [gridApi, setGridApi] = useState(null);
  const [tab, setTab] = useState('not-confirmed');
  const [rowData, setRowData] = useState([]);
  const baseApiUrl = useContext(BaseApiUrlContext);
  const { setSelectedRows, updatedRows } = useContext(AdminBookingContext);

  const filterUpdatedRows = rows => rows.filter(row => updatedRows.map(item => item.bookingId).indexOf(row.bookingId) === -1);

  useEffect(() => fetchBooking(baseApiUrl, tab).then(data => setRowData(filterUpdatedRows(data))), [baseApiUrl, updatedRows]);

  const onTabSelected = tabSelected => {
    if (tabSelected !== tab) {
      setTab(tabSelected);
      fetchBooking(baseApiUrl, tabSelected).then(data => setRowData(data));
    }
  }

  const onGridReady = params => setGridApi(params.api);
  const onSelectionChanged = () => setSelectedRows(gridApi.getSelectedRows());
  const updateBookings = () => fetchBooking(baseApiUrl, tab).then(data => setRowData(filterUpdatedRows(data)));

  return (
    <Switch>
      <Route path={'/admin/booking'} exact>
        <div className="tabs-wrapper">
          <Tabs
            id="controlled-tab-example"
            style={{'maxHeight': '45px'}}
            activeKey={tab}
            onSelect={tab => onTabSelected(tab)}
          >
            <Tab eventKey="not-confirmed" title="Необработанные">
              <div className="admin-table_in-tab ag-theme-alpine">
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  rowSelection={'multiple'}
                  suppressRowClickSelection={true}
                  onSelectionChanged={onSelectionChanged}
                  onGridReady={onGridReady}
                >
                </AgGridReact>
              </div>
            </Tab>
            <Tab eventKey="confirmed" title="Обработанные">
              <div className="admin-table_in-tab ag-theme-alpine">
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefsConfirmed}
                  rowSelection={'multiple'}
                  suppressRowClickSelection={true}
                >
                </AgGridReact>
              </div>
            </Tab>
          </Tabs>
        </div>
      </Route>
      <Route path={'/admin/booking/create'} exact>
        <AdminBookingForm updateBookings={updateBookings} />
      </Route>
    </Switch>
  );
}

export default AdminBooking;
