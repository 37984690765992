import React, { useState, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import BaseApiUrlContext from '../../../contexts/baseUrl';
import AdminBookingContext from '../../../contexts/adminBookingContext';

import './bookingNav.css';

function BookingNav() {
  const baseApiUrl = useContext(BaseApiUrlContext);
  const { selectedRows, setUpdatedRows } = useContext(AdminBookingContext);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const onSaveButton = () => {
    const bookingsId = selectedRows.map(book => book.bookingId);

    const options = {
      method: 'PUT',
      body: JSON.stringify({ bookingsId }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetch(`${baseApiUrl}/api/booking/confirm-booking-requests`, options)
      .then(res => {
        if (res.status !== 200) {
          // Ошибка подтверждения заявки
        } else {
          setUpdatedRows(selectedRows);
          setShow(true);
        }
      });

  };

  return (
    <>
      <Button className="create-booking-button" variant="outline-info">
        <Link to="/admin/booking/create" className="create-booking-link create-room-link">Создать новое бронирование</Link>
      </Button>
      <Button className="save-booking-button" variant="outline-primary" onClick={onSaveButton}>
        Сохранить изменения
      </Button>

      <div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Сохранение изменений</Modal.Title>
          </Modal.Header>
          <Modal.Body>Заявки успешно подтверждены!</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
              Ок
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default BookingNav;
