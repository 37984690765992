import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route, Link, useRouteMatch, useHistory } from 'react-router-dom';
import BaseApiUrlContext from '../../contexts/baseUrl';

import AdminHeader from '../adminHeader';
import AdminPromotions from '../adminPromotions/adminPromotions';
import AdminRooms from '../adminRooms';
import AdminMenu from '../adminMenu';
import AdminContacts from '../adminContacts/adminContacts';
import AdminStatistics from '../adminStatistics';
import AdminBooking from '../adminBooking';
import AdminNews from '../adminNews';
import AdminLoader from '../adminLoader';
import links from '../adminMenu/links';

import AdminStatisticsContext from '../../contexts/adminStatistics';
import AdminBookingContext from '../../contexts/adminBookingContext';

import './adminPage.css';

function AdminPage() {
  // AdminStatisticsContext
  const [statistics, setStatistics] = useState([]);
  const [columnsDefs, setColumnsDefs] = useState([]);

  // AdminBookingContext
  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedRows, setUpdatedRows] = useState([]);

  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const baseApiUrl = useContext(BaseApiUrlContext);
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    const fetchAdminData = async () => {
      const res = await fetch(`${baseApiUrl}/api/admin`, {
        headers: {
          'x-access-token': localStorage.getItem('token')
        }
      });

      const data = await res.json();
      if (data.auth) {
        setIsLogin(true);
      }
      setIsLoading(false);
    }
    fetchAdminData();
  }, [baseApiUrl]);

  if (!isLogin && !isLoading) {
    return <div>Sorry, you are not login <Link to="/admin-login">Go to Login</Link></div>;
  }

  const content = (
    <AdminStatisticsContext.Provider value={{ statistics, setStatistics, columnsDefs, setColumnsDefs }}>
      <AdminBookingContext.Provider value={{ selectedRows, setSelectedRows, updatedRows, setUpdatedRows }}>
        <div className="admin-page">
          <AdminHeader />
          <div className="admin-wrapper">
            <AdminMenu selected={links.find(link => history.location.pathname.includes(link.linkTo)).idx} />
            <Switch>
                <Route path={`${match.url}/booking`}>
                  <AdminBooking />
                </Route>
                <Route path={`${match.url}/rooms`}>
                  <AdminRooms />
                </Route>
                <Route path={`${match.url}/promotions`}>
                  <AdminPromotions />
                </Route>
                <Route path={`${match.url}/news`}>
                  <AdminNews />
                </Route>
                <Route path={`${match.url}/contacts`}>
                  <AdminContacts />
                </Route>
                <Route path={`${match.url}/statistics`}>
                  <AdminStatistics />
                </Route>
            </Switch>
          </div>
        </div>
      </AdminBookingContext.Provider>
    </AdminStatisticsContext.Provider>
  );

  return (
    <>
      {isLoading ? <AdminLoader /> : content}
    </>
  );
}

export default AdminPage;
