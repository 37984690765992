import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import getColumnDefs, { BOOKED_ROOM, ALL_ROOMS } from '../../adminStatistics/grid/columnDefs';
import  AdminStatisticsContext from '../../../contexts/adminStatistics';
import BaseApiUrlContext from '../../../contexts/baseUrl';

import './statisticsNav.css';

const fetchStatistics = async (baseApiUrl, startDate, endDate) => {
  const res = await fetch(`${baseApiUrl}/api/booking/statistics?startDate=${startDate}&endDate=${endDate}`);
  const data = await res.json();
  const stats = [];

  data.forEach(room => {
    stats.push({type: room.type});
    stats.push({type: BOOKED_ROOM, value: room.bookedRoomsNumber});
    stats.push({type: ALL_ROOMS, value: room.roomsNumber});
    stats.push({type: ''});
  });

  stats.pop(); // delete last empty line
  return stats;
}

function StatisticsNav() {
  const [isIncorrectDates, setIsIncorrectDates] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().subtract(-10, 'days').format('YYYY-MM-DD'));

  const baseApiUrl = useContext(BaseApiUrlContext);
  const { setColumnsDefs, setStatistics } = useContext(AdminStatisticsContext);

  useEffect(() => fetchStatistics(baseApiUrl, startDate, endDate).then(stats => setStatistics(stats))
    .then(() => setColumnsDefs(getColumnDefs(startDate, moment(endDate).diff(startDate, 'days') + 1))), [baseApiUrl]);

  const updateStatistics = () => {
    if (moment(endDate).diff(startDate, 'days') >= 0) {
      setIsIncorrectDates(false);
      fetchStatistics(baseApiUrl, startDate, endDate).then(stats => setStatistics(stats))
        .then(() => setColumnsDefs(getColumnDefs(startDate, moment(endDate).diff(startDate, 'days') + 1)));
    } else {
      setIsIncorrectDates(true);
    }
  };

  return (
    <>
      <label htmlFor="start-date">C</label>
      <input 
        type="date"
        name="start-date"
        value={startDate}
        onChange={e => setStartDate(e.target.value)}
      />
      <label htmlFor="end-date">По</label>
      <input 
        type="date"
        name="end-date"
        value={endDate}
        min={moment(startDate).subtract(-1, 'days').format('YYYY-MM-DD')}
        max={moment(startDate).subtract(-30, 'days').format('YYYY-MM-DD')}
        onChange={e => setEndDate(e.target.value)}
      />
      <Button variant="success" onClick={() => updateStatistics()}>Применить</Button>
      {isIncorrectDates ? 'Дата "до" должна быть позже даты "с" ' : ''}
    </>
  );
}

export default StatisticsNav;
