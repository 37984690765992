import React, { useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import AdminStatisticsContext from '../../contexts/adminStatistics'; 

import './adminStatistics.css';

function AdminStatistics() {
  const { statistics, columnsDefs } = useContext(AdminStatisticsContext);

  return (
    <>
      <div className="admin-table ag-theme-alpine" id="admin-statistics-table">
        <AgGridReact
          rowData={statistics}
          columnDefs={columnsDefs}
        >
        </AgGridReact>
      </div>
    </>    
  );
}

export default AdminStatistics;
