import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ListGroup } from 'react-bootstrap';

import links from './links';

import './adminMenu.css';

function AdminMenu(props) {
  const [selected, setSelected] = useState(props.selected);
  const match = useRouteMatch();

  return (
    <ListGroup className="admin-menu">
      {links.map(link => (
        <ListGroup.Item
          className="menu-button"
          action
          key={link.idx}
          active={selected === link.idx}
          onClick={() => setSelected(link.idx)}
        >
          <Link
            to={`${match.url}/${link.linkTo}`}
            className={selected === link.idx ? 'menu-link menu-link__active' : 'menu-link'}
          >
            {link.label}
          </Link>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

export default AdminMenu;