import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import AdminRoomForm from '../adminRoomForm';
import Modal from '../modal';
import columnDefs from './grid/columnDefs';
import getFrameworkComponents from './grid/frameworkComponents';
import BaseApiUrlContext from '../../contexts/baseUrl';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './adminRooms.css';

const fetchRooms = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/rooms`);
  return await res.json();
}

function AdminRooms() {
  const [rowData, setRowData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editRoom, setEditRoom] = useState(null);
  const [deleteRoom, setDeleteRoom] = useState(null);
  const baseApiUrl = useContext(BaseApiUrlContext);

  useEffect(() => fetchRooms(baseApiUrl).then(data => setRowData(data)), [baseApiUrl]);

  const onGridReady = params => params.api.bindRendererFuncs = {onEditRoom, onDeleteRoom};

  const onEditRoom = data => setEditRoom(data);

  const onModalClose = () => setShowModal(false);

  const updateRooms = () => fetchRooms(baseApiUrl).then(data => setRowData(data));

  function onDeleteRoom(data) {
    setShowModal(true);
    setDeleteRoom(data);
  }

  function onAgreeDeleteRoom() {
    setShowModal(false);

    fetch(`${baseApiUrl}/api/rooms/${deleteRoom.roomId}`, {method: 'DELETE'})
      .then(res => {
        if (res.status !== 200) {
          // Ошибка удаления типа комнаты
        } else {
          updateRooms();
        }
      });
  }

  return (
    <Switch>
      <Route path={'/admin/rooms'} exact>
        <div className="rooms-table-wrapper">
          <div className="admin-rooms-table ag-theme-alpine" id="admin-rooms-table">
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              frameworkComponents={getFrameworkComponents()}
              onGridReady={onGridReady}
              domLayout={'autoHeight'}
            >
            </AgGridReact>
          </div>
        </div>
        {showModal ? <Modal onModalClose={onModalClose} onAgreeDeleteRoom={onAgreeDeleteRoom} /> : null}
      </Route>
      <Route path={'/admin/rooms/edit'}>
        <AdminRoomForm editRoom={editRoom} updateRooms={updateRooms} />
      </Route>
      <Route path={'/admin/rooms/create'}>
        <AdminRoomForm updateRooms={updateRooms} />
      </Route>
    </Switch>
  );
}

export default AdminRooms;
