const columnDefs = [
  {
    headerName: 'No.',
    field: 'id',
    cellStyle: { fontSize: '17px', textAlign: 'center' },
    minWidth: 50,
    width: 50
  },
  {
    headerName: 'Заголовок',
    field: 'heading',
    cellStyle: { fontSize: '18px' },
    minWidth: 100,
    width: 400
  },
  {
    headerName: '',
    minWidth: 100,
    width: 100,
    cellRenderer: 'editButtonRenderer',
    cellRendererParams: { path: '/admin/news/edit' },
    suppressSizeToFit: true
  },
  {
    headerName: '',
    minWidth: 100,
    width: 100,
    cellRenderer: 'deleteButtonRenderer',
    suppressSizeToFit: true
  }
];

export default columnDefs;