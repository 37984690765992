import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import BaseUrlContext from '../../contexts/baseUrl';

import './adminLoginPage.css';

function AdminLoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const history = useHistory();
  const baseUrl = useContext(BaseUrlContext);

  const onLogin = async e => {
    e.preventDefault();

    const options = {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const res = await fetch(`${baseUrl}/auth/login`, options);
    const data = await res.json();

    if (data.auth) {
      localStorage.setItem('token', data.token);
      history.push('/admin/rooms'); // redirect on Admin page
    } else {
      // Wrong password
      setPassword('');
      setError(true);
    }
  }

  return (
    <div className="admin-login-page">
      <div>Подъельники</div>
      <Form onSubmit={onLogin}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Form.Text className="text-muted">
            Мы никогда никому не передадим вашу электронную почту.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Пароль</Form.Label>
          <Form.Control 
            type="password" 
            placeholder="Пароль" 
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit">
          Войти
        </Button>
        {error ? <p className="authorization-error">Введён неверный пароль либо email</p> : ''}
      </Form>
    </div>   
  );
}

export default AdminLoginPage;
