import React from 'react';
import { Button } from 'react-bootstrap';

function DeleteButtonRenderer(props) {
  const onDelete = () => props.api.bindRendererFuncs.onDeleteRoom(props.data);

  return (
    <Button variant="outline-danger delete-button" onClick={onDelete}>Удалить</Button>
  );  
}

export default DeleteButtonRenderer;
