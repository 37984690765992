import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

function EditButtonRenderer(props) {
  const onEdit = () => props.api.bindRendererFuncs.onEditRoom(props.data);
  
  return (
    <Button variant="outline-primary edit-button" onClick={onEdit}>
      <Link className="edit-link" to={props.path}>Изменить</Link>
    </Button>
  );
}

export default EditButtonRenderer;
