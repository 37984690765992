import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';
import BaseUrlContext from '../../contexts/baseUrl';

import './adminBookingForm.css';

const fetchRooms = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/rooms`);
  return await res.json();
}

function AdminBookingForm({ updateBookings }) {
  const [room, setRoom] = useState({});
  const [roomTypes, setRoomTypes] = useState([]);
  const [checkInDate, setCheckInDate] = useState(moment().format('YYYY-MM-DD'));
  const [checkOutDate, setCheckOutDate] = useState(moment().subtract(-10, 'days').format('YYYY-MM-DD'));
  const [guestsNumber, setGuestsNumber] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');

  const history = useHistory();
  const baseApiUrl = useContext(BaseUrlContext);

  useEffect(() => fetchRooms(baseApiUrl).then(data => setRoomTypes(data)), [baseApiUrl]);

  const onCancel = () => history.goBack();
  
  const onSubmit = (e) => {
    e.preventDefault();
    const row = { roomId: room.roomId, checkInDate, checkOutDate, guestsNumber, firstName, lastName, phone };

    const options = {
      method: 'POST',
      body: JSON.stringify(row),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetch(`${baseApiUrl}/api/booking/book-admin`, options)
      .then(res => {
        if (res.status !== 200) {
          // Ошибка бронирования
        } else {
          updateBookings();
          history.goBack();
        }
      });
  };

  return (
    <div className="edit-room">
      <div>Создать новое бронирование</div>
      <Form className="edit-form" onSubmit={onSubmit}>
        <Dropdown>
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {room.type || 'Выберите тип комнаты'}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {roomTypes.map(room => (
              <Dropdown.Item onClick={() => setRoom(room)} key={room.roomId}>{room.type}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Form.Group>
          <Form.Label>C</Form.Label>
          <Form.Control
            type="date"
            value={checkInDate}
            onChange={e => setCheckInDate(e.target.value)}
          />
          <Form.Label>По</Form.Label>
          <Form.Control
            type="date"
            placeholder="Тип комнаты"
            value={checkOutDate}
            min={moment(checkInDate).subtract(-1, 'days').format('YYYY-MM-DD')}
            max={moment(checkInDate).subtract(-30, 'days').format('YYYY-MM-DD')}
            onChange={e => setCheckOutDate(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Количество людей</Form.Label>
          <Form.Control
            type="number"
            value={guestsNumber}
            onChange={e => setGuestsNumber(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Имя</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Фамилия</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Телефон</Form.Label>
          <Form.Control
            type="text"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </Form.Group>
        <Button variant="danger" onClick={onCancel}>
          Отмена
        </Button>
        <Button variant="primary" type="submit">
          Сохранить изменения
        </Button>
      </Form>
    </div>   
  );
}

export default AdminBookingForm;
