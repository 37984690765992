import React, {useState, useEffect, useContext} from 'react';
import {Carousel} from 'react-bootstrap';
import BaseApiUrlContext from '../../contexts/baseUrl';

import './promotions.css';

const fetchPromotions = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/promotions`);
  return await res.json();
}

function Promotions() {
  const [promotions, setPromotions] = useState([]);
  const [index, setIndex] = useState(0);
  const baseApiUrl = useContext(BaseApiUrlContext);

  useEffect(() => fetchPromotions(baseApiUrl).then(data => setPromotions(data)), [baseApiUrl]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // const promotions = [{heading: 'Досуг', description: 'Тематические вечера. Экскурсии. Конкурсы. Концерты. Танцевально-игровая программа. Сезонные гуляния и праздники и еще многое другое.', photoName: "promotion5_0.jpg,promotion5_1.jpg"},
  // {heading: 'Досуг', description: 'Тематические вечера. Экскурсии. Конкурсы. Концерты. Танцевально-игровая программа. Сезонные гуляния и праздники и еще многое другое.', photoName: "promotion5_0.jpg,promotion5_1.jpg"}];

  return (
      <Carousel className="promotions" interval={null} activeIndex={index} onSelect={handleSelect}>
        {promotions.map(({ heading, description, photoName }, idx) => {
          return (
            <Carousel.Item key={idx}>
              <ImageCarousel photoName={photoName} baseApiUrl={baseApiUrl} />
              <Carousel.Caption>
                <h3>{heading}</h3>
                <p>{description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
  );
}

function ImageCarousel ({ photoName, baseApiUrl }) {

  return (
    <Carousel className="promotions-2" fade>
      {photoName.split(',').map((photo, idx) => (
        <Carousel.Item key={idx}>
          <img className="d-block" src={`${baseApiUrl}/static/${photo}`} alt="МЕДИЦИНСКИЕ УСЛУГИ"/>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default Promotions;
