const columnDefs = [
  {
    headerName: 'No.',
    field: 'roomId',
    cellStyle: { fontSize: '17px', textAlign: 'center' },
    minWidth: 50,
    width: 50
  },
  {
    headerName: 'Тип комнаты',
    field: 'type',
    cellStyle: { fontSize: '18px' },
    minWidth: 100,
    width: 400
  },
  {
    headerName: 'Количество номеров',
    field: 'roomsNumber',
    cellStyle: { fontSize: '17px', textAlign: 'center' },
    minWidth: 100,
    width: 150
  },
  {
    headerName: '',
    minWidth: 100,
    width: 100,
    cellRenderer: 'editButtonRenderer',
    cellRendererParams: { path: '/admin/rooms/edit' },
    suppressSizeToFit: true
  },
  {
    headerName: '',
    minWidth: 100,
    width: 100,
    cellRenderer: 'deleteButtonRenderer',
    suppressSizeToFit: true
  }
];

export default columnDefs;