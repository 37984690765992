import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Landing from '../landing';
import AdminLoginPage from '../adminLoginPage';
import AdminPage from '../adminPage';
import BaseApiUrlContext, { baseApiUrl } from '../../contexts/baseUrl';

import './app.css';

function App() {
  return (
    <BaseApiUrlContext.Provider value={baseApiUrl}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <Landing />
          </Route>
          <Route path="/admin-login" exact>
            <AdminLoginPage />
          </Route>
          <Route path="/admin">
            <AdminPage />
          </Route>
        </Switch>
      </Router>
    </BaseApiUrlContext.Provider>
  );
}

export default App;
