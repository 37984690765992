import moment from 'moment';
import 'moment/locale/ru'; 

export const BOOKED_ROOM = 'Забронированные';
export const ALL_ROOMS = 'Всего';

const getColumnDefs = (startDate, days) => {
  const lastThirtyDays = [...new Array(days)].map((i, idx) => moment(startDate).startOf('day').subtract(-idx, 'days').format('ll'));

  const dates = lastThirtyDays.map((date, idx) => ({
    headerName: date,
    width: 135,
    resizable: true,
    cellStyle: {textAlign: 'center'},
    colId: idx,
    valueGetter: params => {
      switch(params.data.type) {
        case BOOKED_ROOM:
          return params.data.value[idx];
        case ALL_ROOMS:
          return params.data.value;
        default:
          return '';
      }
    }
  }));

  return [
    {
      headerName: '',
      width: 300,
      cellStyle: {paddingLeft: '15px'},
      valueGetter: params => params.data.type
    },
    ...dates,
  ];
}

export default getColumnDefs;