import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from 'react-router-dom';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import moment from 'moment';

import Promotions from '../promotions';
import News from '../news';

import BaseApiUrlContext from '../../contexts/baseUrl';

import './landing.css';

const MyModal = React.memo(function MyModal (props) {
  const [checkInDate, setCheckInDate] = useState(moment().format('YYYY-MM-DD'));
  const [checkOutDate, setCheckOutDate] = useState(moment().subtract(-10, 'days').format('YYYY-MM-DD'));
  const [guestsNumber, setGuestsNumber] = useState(1);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const baseApiUrl = useContext(BaseApiUrlContext);

  const onSubmit = e => {
    e.preventDefault();
    const { roomId } = props.selectedRoom;

    if (!firstName || !lastName || !phone) {
      return setError(true);
    }

    const options = {
      method: 'POST',
      body: JSON.stringify({ roomId, checkInDate, checkOutDate, guestsNumber, firstName, lastName, phone, email }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    fetch(`${baseApiUrl}/api/booking/book-guest`, options)
      .then(res => {
        if (res.status !== 200) {
          // Ошибка оставления заявки
        } else {
          props.setModalShow(false);
          props.setShow(true);
        }
      })
  };

  return (
    <Modal
      show={props.modalShow}
      onHide={() => props.setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-book-room"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Забронировать номер</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Title className="booking-form-title">{props.selectedRoom ? props.selectedRoom.type : ''}</Card.Title>
        <div className="booking-form-image">
          <img src={props.selectedRoom ? `${baseApiUrl}/static/${props.selectedRoom.photoName}` : null} />
        </div>
        <p>{props.selectedRoom ? props.selectedRoom.description : ''}</p>
        <div className="create-booking-form-wrapper booking-form">
          <Form className="create-booking-form" onSubmit={onSubmit}>
            <Form.Group>
              <div className="dates">
                <Form.Label>C</Form.Label>
                <Form.Control
                  type="date"
                  value={checkInDate}
                  onChange={e => setCheckInDate(e.target.value)}
                />
                <Form.Label>По</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Тип комнаты"
                  value={checkOutDate}
                  min={moment(checkInDate).subtract(-1, 'days').format('YYYY-MM-DD')}
                  max={moment(checkInDate).subtract(-30, 'days').format('YYYY-MM-DD')}
                  onChange={e => setCheckOutDate(e.target.value)}
                />
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label>Количество людей</Form.Label>
              <Form.Control
                type="number"
                value={guestsNumber}
                onChange={e => setGuestsNumber(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Имя</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Фамилия</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Телефон</Form.Label>
              <Form.Control
                type="text"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Почта</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
            <div className="book-room-wrapper">
              {error ? <p className="booking-error">Пожалуйста, заполните все поля, чтобы мы смогли с вами связаться</p> : ''}
              <button type="submit" className="book-room">Оставить заявку</button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
});


const NewsModal = React.memo(function MyModal (props) {
  return (
    <Modal
      show={props.newsModalShow}
      onHide={() => props.setNewsModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-book-room"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Полный текст новости</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Title className="booking-form-title">{props.selectedNews ? props.selectedNews.heading : ''}</Card.Title>
        <p className="modal-description-news">{props.selectedNews ? props.selectedNews.description : ''}</p>
      </Modal.Body>
    </Modal>
  )
});

const fetchRooms = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/rooms`);
  return await res.json();
}

const fetchContacts = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/contacts`);
  return await res.json();
}

function Landing() {
  const [modalShow, setModalShow] = useState(false); // Modal with full room type description
  const [show, setShow] = useState(false); // Modal after success booking
  const [newsModalShow, setNewsModalShow] = useState(false);
  
  const [selectedNews, setSelectedNews] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomTypes, setRoomTypes] = useState([]);
  const [contacts, setContacts] = useState([]);
  const baseApiUrl = useContext(BaseApiUrlContext);

  const { ymaps } = window;
 
  useEffect(() => {
    ymaps.ready(init);
    fetchRooms(baseApiUrl).then(data => setRoomTypes(data)); 
    fetchContacts(baseApiUrl).then(data => setContacts(data));
  }, []);

  function init() {
    var myMap = new ymaps.Map("map", {
      center: [53.353517, 27.112681],
      zoom: 8
    }, {
      searchControlProvider: 'yandex#search'
    });

    myMap.geoObjects.add(new ymaps.Placemark([53.353517, 27.112681], {
      balloonContent: '<strong>Подъельники</strong>'
    }, 
    {
      iconColor: '#f44336',
      iconImageSize: [120, 120]
    }));   
    
    myMap.behaviors.disable('scrollZoom');
  }
  
  const onReadFull = news => {
    setSelectedNews(news);
    setNewsModalShow(true);
  };

  return (
    <>
      <header>
        <ul>
          <li><a href="#promotions">Услуги</a></li>
          <li><a href="#about-us">О нас</a></li>
          <li><a href="#contacts">Контакты</a></li>
        </ul>
      </header>
      <div className="welcome-page">
        <h1>Добро пожаловать в уникальную белорусскую здравницу</h1>
        <div className="welcome-page-booking"><a href="#booking-cards">Онлайн бронирование - оставить заявку ниже</a></div>
      </div>
      <h3 className="yandex-map-heading">Оставить заявку на номер</h3>
      <div className="booking-cards-wrapper">
        <div className="booking-cards" id="booking-cards">
          {roomTypes.map(room =>
            <Card 
              key={room.roomId}
              className="booking-card"
              onClick = {() => { setSelectedRoom(room); setModalShow(true) }}
            >
              <Card.Img variant="top" className="card-image" src={`${baseApiUrl}/static/${room.photoName}`} />
              <Card.Body>
                <Card.Title>{room.type}</Card.Title>
                <Card.Text>{room.description}</Card.Text>
                <div className="booking-card__prices">
                  <div className="booking-card__price">{room.pricePerDay} BYN</div>
                  <div className="booking-card__price">{room.pricePerDayRu} RUB</div>
                </div>
                <div className="book-room">Оставить заявку</div>
              </Card.Body>
            </Card>
          )}
          <MyModal modalShow={modalShow} setModalShow={setModalShow} selectedRoom={selectedRoom} setShow={setShow} />
          <NewsModal newsModalShow={newsModalShow} setNewsModalShow={setNewsModalShow} selectedNews={selectedNews} />
        </div>
      </div>
      <News onReadFull={onReadFull} />
      <h3 className="yandex-map-heading">Встреча Нового 2022 Года!</h3>
      <div class="youtube_video">
        <iframe width="560" height="315" src="https://ok.ru/videoembed/3926540225074" frameborder="0" allow="autoplay" allowFullScreen></iframe>
      </div>
      <div className="about-us" id="about-us">
        <h3>О нас</h3>
        <div className="text">
          Санаторий “Подъельники” расположен в экологически чистой зоне в смешанном лесу у слияния рек: Уса, Лоша, Неманец, образующих неповторимо величавый Неман, несущий свои воды далеко по просторам Беларуси и Прибалтики.
          Санаторий представляет собой четырехэтажный корпус современного дизайна, рассчитанный на 100 мест.
          <span><br /></span>
          <span><br /></span>
          Профиль лечения:
          <ul>
            <li>болезни органов дыхания;</li>
            <li>болезни системы кровообращения;</li>
            <li>болезни костно-мышечной системы;</li>
            <li>болезни желудочно-кишечного тракта.</li>
          </ul>
          <span><br /></span>
          Санаторий имеет свою собственную лечебную базу. Физиотерапевтическое отделение оборудовано современной аппаратурой, позволяющей осуществлять не только профилактику, но и лечение заболеваний.
          Электросветолечение, тепло-грязелечение, магнита-лазеротерапия, кабинет психоэмоциональной разгрузки с ароматерапией, карбокситерапия, массаж ручной и механический, прессотерапия, сухая углекислая ванна, инфракрасная сауна, ингаляторий, фитобар с травяными сборами и кислородным коктейлем, скипидарные ванны, лечебная физкультура, скандинавская ходьба, галоспелеотерапия (соляная пещера); комплекс водных процедур: душ Шарко, циркулярный душ, лечебные ванны, ручной подводный и механический аэрогидромассаж, косметический омолаживающий массаж лица; функциональная диагностика и другие.
          Имеется возможность обследоваться у офтальмолога на современном оборудовании.
          На территории санатория имеется природный источник минеральной воды, насыщенный фтором, которая дает положительный результат в лечении и профилактике заболеваний зубов и костной ткани, хронических гастритов и энтероколитов, хронических заболеваний печени и желчевыводящих путей, болезней обмена веществ, хронических заболеваний мочевыводящих путей.
          В санатории “Подъельники” присутствуют как культурные, так и спортивно-оздоровительные мероприятия. 
          <div><br /></div>
          К услугам санатория можно отнести:
          <ul>
            <li>современный тренажерный зал;</li>
            <li>спортивный и актовый залы;</li>
            <li>библиотека;</li>
            <li>прокат спортинвентаря;</li>
            <li>велосипеды и лыжи.</li>
          </ul>
        </div>
      </div>
      <h3 className="yandex-map-heading" id="promotions">Услуги</h3>
      <Promotions />
      <h3 className="yandex-map-heading">Наше местоположение</h3>
      <div className="yandex-map">
        <div id="map"></div>
      </div>
      <footer id="contacts">
        { contacts.length ? contacts.map((str, idx) => <p key={idx}>{str}<span><br/></span></p>) : null }
        <hr/>
        <p className="underscore">
          <Link to="/admin-login" target="_blank" rel="noopener noreferrer">
            Санаторий    
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-door-open" viewBox="0 0 16 16">
              <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/>
              <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z"/>
            </svg>
          </Link>
          <span>© 2021 - Podjelniki  официальный сайт бронирования</span>
        </p>
      </footer>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Онлайн-бронирование</Modal.Title>
        </Modal.Header>
        <Modal.Body>Заявка на комнату успешно подтверждена!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            Ок
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Landing;