import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import BookingNav from './bookingNav';
import StatisticsNav from './statisticsNav';

import './adminHeader.css';

function AdminHeader() {
  return (
    <div className="admin-header">
      <Link to= "/" className="create-room-link landing-link">Вернуться на сайт</Link>
      <Switch>
        <Route path={'/admin/booking'} exact>
          <BookingNav />
        </Route>
        <Route path={'/admin/rooms'} exact>
          <Button className="create-room-button" variant="outline-info">
            <Link to="/admin/rooms/create" className="create-room-link">Создать новый тип комнаты</Link>
          </Button>
        </Route>
        <Route path={'/admin/promotions'} exact>
          <Button className="create-room-button" variant="outline-info">
            <Link to="/admin/promotions/create" className="create-room-link">Создать новую услугу</Link>
          </Button>
        </Route>
        <Route path={'/admin/news'} exact>
          <Button className="create-room-button" variant="outline-info">
            <Link to="/admin/news/create" className="create-room-link">Создать новую новость</Link>
          </Button>
        </Route>
        <Route path={'/admin/statistics'} exact>
          <StatisticsNav />
        </Route>
      </Switch>
    </div>
  );
}

export default AdminHeader;
