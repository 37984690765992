import React from 'react';
import { Modal as ModalReact, Button } from 'react-bootstrap';

import './modal.css';

function Modal(props) {
  return (
    <ModalReact show={true} onHide={props.onModalClose}>
      <ModalReact.Header closeButton>
        <ModalReact.Title>Удаление</ModalReact.Title>
      </ModalReact.Header>
      <ModalReact.Body>Вы действительно хотите удалить этот элемент с сайта?</ModalReact.Body>
      <ModalReact.Footer>
        <Button variant="secondary" onClick={props.onModalClose}>
          Назад
        </Button>
        <Button variant="primary" onClick={props.onAgreeDeleteRoom}>
          Да
        </Button>
      </ModalReact.Footer>
    </ModalReact>
  );
}

export default Modal;
