import React from 'react';
import { Spinner } from 'react-bootstrap';

import './adminLoader.css';

function AdminLoader() {

  return (
    <div className="spinner-wrapper">
      <Spinner className="admin-spinner" animation="border" variant="primary" />
    </div>
  );
}

export default AdminLoader;
