import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import BaseUrlContext from '../../contexts/baseUrl';

import './adminPromotionsForm.css';

function AdminPromotionsForm({ editRoom, updateRooms }) {
  const baseApiUrl = useContext(BaseUrlContext);
  const [heading, setHeading] = useState(editRoom ? editRoom.heading : '');
  const [desc, setDesc] = useState(editRoom ? editRoom.description : '');
  const [imageSrc, setImageSrc] = useState(editRoom ? editRoom.photoName.split(',') : []);
  const [imageDownloaded, setImageDownloaded] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const inputEl = useRef(null);
  const history = useHistory();

  const onCancel = () => history.goBack();
  
  const onSubmit = (e) => {
    e.preventDefault();

    const options = {
      body: JSON.stringify({ heading, description: desc }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const downloadImage = async id => {
      const formData = new FormData();
      let options;
      [...inputEl.current.files].forEach(image => {
        formData.append('file', image);

        options = {
          method: 'POST',
          body: formData
        }
      });
      return await fetch(`${baseApiUrl}/api/promotions/upload-image/${id}`, options);
    };

    const createRoom = async () => {
      const res = await fetch(`${baseApiUrl}/api/promotions`, {...options, method: 'POST'});

      if (res.status !== 200) {
        // Ошибка удаления создания типа комнаты
      }

      const data = await res.json();
      await downloadImage(data.insertId);

      updateRooms();
      history.goBack();
    }

    const updateRoom = async () => {
      const res = await fetch(`${baseApiUrl}/api/promotions/${editRoom.id}`, {...options, imageDownloaded, method: 'PUT'});

      if (res.status !== 200) {
        // Ошибка изменения создания типа комнаты
      }

      const data = await res.json();
      if (imageDownloaded) {
        await downloadImage(editRoom.id);
      }

      updateRooms();
      history.goBack();
    }

    editRoom ? updateRoom() : createRoom();
  };

  const previewImage = () => {
    setImageDownloaded(true);
    setIsPreview(true);
    setImageSrc([]);

    [...inputEl.current.files].forEach(image => {
      const reader = new FileReader();

      reader.onloadend = function () {
        setImageSrc(prev => [...prev, reader.result]);
      }

      if (image) {
        reader.readAsDataURL(image);
      }
    });
  };

  return (
    <div className="edit-room">
      <div>{editRoom ? 'Изменить' : 'Создать'} услугу</div>
      <Form className="edit-form" encType="multipart/form-data" onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Заголовок услуги</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Заголовок"
            value={heading}
            onChange={e => setHeading(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Описание услуги</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={7} 
            placeholder="Описание"
            value={desc}
            onChange={e => setDesc(e.target.value)} />
          {imageSrc.map((src, idx) => <img className="preview-image" src={isPreview ? src : `${baseApiUrl}/static/${src}`} key={idx} />)}
          <label htmlFor="image-upload">Выберите картинки для загрузки</label>
          <input 
            type="file" 
            name="imageUpload" 
            accept=".jpg, .jpeg, .png"
            multiple={true}
            onChange={previewImage}
            ref={inputEl}
          />
        </Form.Group>
        <Button variant="danger" onClick={onCancel}>
          Отмена
        </Button>
        <Button variant="primary" type="submit">
          Сохранить изменения
        </Button>
      </Form>
    </div>   
  );
}

export default AdminPromotionsForm;
