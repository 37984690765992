export const columnDefs = [
  {
    headerName: 'Подтвердить заявку',
    field: '',
    cellStyle: {display: 'flex', justifyContent: 'center'},
    checkboxSelection: true,
    sortable: true,
    comparator: checkboxComparator,
    minWidth: 150,
    width: 150
  },
  {
    headerName: 'Время бронирования',
    field: 'bookingDate',
    cellStyle: {textAlign: 'center'},
    sortable: true,
    minWidth: 150,
    width: 150
  },
  {
    headerName: 'Тип номера',
    field: 'roomType',
    sortable: true,
    minWidth: 200,
    width: 200
  },
  {
    headerName: 'Время заезда',
    field: 'checkInDate',
    sortable: true,
    minWidth: 100,
    width: 100
  },
  {
    headerName: 'Время выезда',
    field: 'checkOutDate',
    sortable: true,
    minWidth: 115,
    width: 115
  },
  {
    headerName: 'Кол-во людей',
    field: 'guestsNumber',
    sortable: true,
    minWidth: 125,
    width: 125
  },
  {
    headerName: 'Имя клиента',
    field: 'guestFirstName',
    minWidth: 125,
    width: 125
  },
  {
    headerName: 'Фамилия клиента',
    field: 'guestLastName',
    minWidth: 100,
    width: 150
  },
  {
    headerName: 'Телефон клиента',
    field: 'guestPhone',
    minWidth: 100,
    width: 150
  },
  {
    headerName: 'Email клиента',
    field: 'guestEmail',
    minWidth: 100,
    width: 150
  }
];

export const columnDefsConfirmed = [
  {
    headerName: 'Время бронирования',
    field: 'bookingDate',
    cellStyle: {textAlign: 'center'},
    sortable: true,
    minWidth: 150,
    width: 150
  },
  {
    headerName: 'Время заезда',
    field: 'checkInDate',
    sortable: true,
    minWidth: 100,
    width: 100
  },
  {
    headerName: 'Время выезда',
    field: 'checkOutDate',
    sortable: true,
    minWidth: 115,
    width: 115
  },
  {
    headerName: 'Кол-во людей',
    field: 'guestsNumber',
    sortable: true,
    minWidth: 125,
    width: 125
  },
  {
    headerName: 'Фамилия клиента',
    field: 'guestLastName',
    minWidth: 100,
    width: 150
  },
  {
    headerName: 'Телефон клиента',
    field: 'guestPhone',
    minWidth: 100,
    width: 150
  }
];

function checkboxComparator(val1, val2) {
  console.log(val1);
  console.log(val2);
}
