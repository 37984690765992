import React, { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import BaseUrlContext from '../../contexts/baseUrl';

import './adminRoomForm.css';

function AdminRoomForm({ editRoom, updateRooms }) {
  const baseApiUrl = useContext(BaseUrlContext);
  const [type, setType] = useState(editRoom ? editRoom.type : '');
  const [desc, setDesc] = useState(editRoom ? editRoom.description : '');
  const [price, setPrice] = useState(editRoom ? editRoom.pricePerDay : '');
  const [priceRu, setPriceRu] = useState(editRoom ? editRoom.pricePerDayRu : '');
  const [bedroomsNumber, setBedroomsNumber] = useState(editRoom ? editRoom.bedroomsNumber : '');
  const [roomsNumber, setRoomsNumber] = useState(editRoom ? editRoom.roomsNumber : '');
  const [imageSrc, setImageSrc] = useState(editRoom ? `${baseApiUrl}/static/${editRoom.photoName}` : null);
  const [imageDownloaded, setImageDownloaded] = useState(false);
  const inputEl = useRef(null);
  const history = useHistory();

  const onCancel = () => history.goBack();
  
  const onSubmit = (e) => {
    e.preventDefault();

    const options = {
      body: JSON.stringify({ type, bedroomsNumber, roomsNumber, description: desc, pricePerDay: price, pricePerDayRu: priceRu }),
      headers: {
        'Content-Type': 'application/json'
      }
    }

    const downloadImage = async id => {
      const formData = new FormData();
      let options;
      [...inputEl.current.files].forEach(image => {
        formData.append('file', image);

        options = {
          method: 'POST',
          body: formData
        }
      });
      return await fetch(`${baseApiUrl}/api/rooms/upload-image/${id}`, options);
    };

    const createRoom = async () => {
      const res = await fetch(`${baseApiUrl}/api/rooms`, {...options, method: 'POST'});

      if (res.status !== 200) {
        // Ошибка удаления создания типа комнаты
      }

      const data = await res.json();
      await downloadImage(data.insertId);

      updateRooms();
      history.goBack();
    }

    const updateRoom = async () => {
      const res = await fetch(`${baseApiUrl}/api/rooms/${editRoom.roomId}`, {...options, imageDownloaded, method: 'PUT'});

      if (res.status !== 200) {
        // Ошибка изменения создания типа комнаты
      }

      const data = await res.json();
      if (imageDownloaded) {
        await downloadImage(editRoom.roomId);
      }

      updateRooms();
      history.goBack();
    }

    editRoom ? updateRoom() : createRoom();
  };

  const previewImage = () => {
    setImageDownloaded(true);
    const image = inputEl.current.files[0];
    const reader = new FileReader();

    reader.onloadend = function () {
      setImageSrc(reader.result);
    }

    if (image) {
      reader.readAsDataURL(image);
    }
  };

  return (
    <div className="edit-room">
      <div>{editRoom ? 'Изменить' : 'Создать'} тип комнаты</div>
      <Form className="edit-form" encType="multipart/form-data" onSubmit={onSubmit}>
        <Form.Group>
          <Form.Label>Тип комнаты</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Тип комнаты"
            value={type}
            onChange={e => setType(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Описание комнаты</Form.Label>
          <Form.Control 
            as="textarea" 
            rows={7} 
            placeholder="Описание комнаты"
            value={desc}
            onChange={e => setDesc(e.target.value)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Цена за день (BYN)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Цена за день"
            value={price}
            onChange={e => setPrice(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Цена за день (RUB)</Form.Label>
          <Form.Control
            type="number"
            placeholder="Цена за день"
            value={priceRu}
            onChange={e => setPriceRu(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Количество спальных мест</Form.Label>
          <Form.Control
            type="number"
            placeholder="Количество спальных мест"
            value={bedroomsNumber}
            onChange={e => setBedroomsNumber(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Количество комнат</Form.Label>
          <Form.Control
            type="number"
            placeholder="Количество комнат"
            value={roomsNumber}
            onChange={e => setRoomsNumber(e.target.value)}
          />
          <img className="preview-image" src={imageSrc} />
          <label htmlFor="image-upload">Выберите картинку для загрузки</label>
          <input 
            type="file" 
            name="imageUpload" 
            accept=".jpg, .jpeg, .png"
            onChange={previewImage}
            ref={inputEl}
          />

        </Form.Group>
        <Button variant="danger" onClick={onCancel}>
          Отмена
        </Button>
        <Button variant="primary" type="submit">
          Сохранить изменения
        </Button>
      </Form>
    </div>   
  );
}

export default AdminRoomForm;
