import React, {useState, useEffect, useContext} from 'react';
import {Carousel} from 'react-bootstrap';
import BaseApiUrlContext from '../../contexts/baseUrl';

import './news.css';

const fetchPromotions = async (baseApiUrl) => {
  const res = await fetch(`${baseApiUrl}/api/news`);
  return await res.json();
}

function News({ onReadFull }) {
  const [promotions, setPromotions] = useState([]);

  const [index, setIndex] = useState(0);
  const baseApiUrl = useContext(BaseApiUrlContext);

  useEffect(() => fetchPromotions(baseApiUrl).then(data => setPromotions(data)), [baseApiUrl]);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  
  if (!promotions.length) {
    return null;
  }

  return (
    <>
      <h3 className="yandex-map-heading" id="promotions">Новости</h3>
      <Carousel className="promotions news" interval={null} activeIndex={index} onSelect={handleSelect}>
      {promotions.map(({ heading, shortDescription, description, photoName }, idx) => {
        return (
          <Carousel.Item key={idx}>
            <ImageCarousel photoName={photoName} baseApiUrl={baseApiUrl} />
            <Carousel.Caption>
              <h3>{heading}</h3>
              <p className="description-news">{shortDescription}</p>
              <div>
                <button 
                  onClick={() => onReadFull({ heading, shortDescription, description, photoName })} 
                  type="submit" 
                  className="button-read-news"
                >
                  Читать полностью
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        );
      })}
      </Carousel>
    </>
  );
}

function ImageCarousel ({ photoName, baseApiUrl }) {

  return (
    <Carousel className="promotions-2 news-2" fade>
      {photoName.split(',').map((photo, idx) => (
        <Carousel.Item key={idx}>
          <div className="d-block" style={ {background: `url(${baseApiUrl}/static/${photo}) no-repeat`, backgroundSize: 'contain'} }></div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default News;
